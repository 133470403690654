import React from "react";
import Header from "./components/header/header";
import Nav from "./components/nav/nav";
import About from "./components/about/About";
import Expirience from "./components/Expirience/Expirience";
import Services from "./components/services/services";
import Portfolio from "./components/portfolio/portfolio";
import Contact from "./components/Contact/Contact";
import Footer from "./components/footer/footer";
const App = () => {
  return (
    <>
      <Header />
      <Nav />
      <About />
      <Expirience />
      <Services />
      <Portfolio />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
