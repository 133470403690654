import React from "react";
import "./footer.css";
import { ImFacebook2 } from "react-icons/im";
import { BsInstagram } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import ME from "../../assets/vijethnarayanlogo.png";

const footer = () => {
  return (
    <footer>
      <a href="#" className="footer_logo">
        <img src={ME} alt="me" />
      </a>

      <ul className="permalinks">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#expirience">Experience</a>
        </li>
        <li>
          <a href="#services">Services</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#Contact">Contact</a>
        </li>
      </ul>
      <div className="footer_socials">
        <a href="https://facebook.com/vijeth.narayan.3/">
          <ImFacebook2 />
        </a>
        <a href="https://www.instagram.com/vijeth_narayan/">
          <BsInstagram />
        </a>
        <a href="https://twitter.com/vijeth_narayan">
          <BsTwitter />
        </a>
        <a href="https://www.linkedin.com/in/vijeth-m">
          <BsLinkedin />
        </a>
      </div>
      <div className="footer_copyright">
        <small>&copy; VIJETH NARAYAN . All rights reserved.</small>
      </div>
    </footer>
  );
};

export default footer;
