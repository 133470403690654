import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { ImFacebook2 } from "react-icons/im";
import { BsTwitter } from "react-icons/bs";

const headersocials = () => {
  return (
    <div className="header_socials">
      <a
        href="https://www.linkedin.com/in/vijeth-m/"
        target="_blank"
        rel="noreferrer"
      >
        <BsLinkedin />
      </a>
      <a
        href="https://www.instagram.com/vijeth_narayan/"
        target="_blank"
        rel="noreferrer"
      >
        <BsInstagram />
      </a>
      <a
        href="https://facebook.com/vijeth.narayan.3/"
        target="_blank"
        rel="noreferrer"
      >
        <ImFacebook2 />
      </a>
      <a
        href="https://twitter.com/vijeth_narayan"
        target="_blank"
        rel="noreferrer"
      >
        <BsTwitter />
      </a>
    </div>
  );
};

export default headersocials;
