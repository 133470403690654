import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/IMG06.png";
import IMG2 from "../../assets/project2.png";
import IMG3 from "../../assets/IMG07.png";
import IMG4 from "../../assets/IMG01.png";
import IMG5 from "../../assets/IMG03.png";
import IMG6 from "../../assets/IMG02.png";
import IMG7 from "../../assets/project7.png";
import IMG8 from "../../assets/IMG08.png";
import IMG9 from "../../assets/IMG04.png";
const portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio_container">
        <article className="portfolio_item">
          <div className="portfolio_image-image"></div>
          <img src={IMG6} alt="" />
          <h3>Adversity Solutions</h3>
          <div className="portfolio_item-cta">
            {" "}
            <a
              href="https://adversitysolutions.in/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              View
            </a>
          </div>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_image-image"></div>
          <img src={IMG1} alt="" />
          <h3>Fewdot</h3>
          <div className="portfolio_item-cta">
            {" "}
            <a
              href="https://fewdot.com/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              View
            </a>
          </div>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_image-image"></div>
          <img src={IMG2} alt="" />
          <h3>Suviva Yards</h3>
          <div className="portfolio_item-cta">
            {" "}
            <a
              href="https://suvivayards.com"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              View
            </a>
          </div>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_image-image"></div>
          <img src={IMG9} alt="" />
          <h3>Technotut Solutions</h3>
          <div className="portfolio_item-cta">
            {" "}
            <a
              href="https://technotutsolutions.com/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              View
            </a>
          </div>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_image-image"></div>
          <img src={IMG3} alt="" />
          <h3>Aiva Solution Integrations</h3>
          <div className="portfolio_item-cta">
            {" "}
            <a
              href="https://aivasolutionintegrations.com/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              View
            </a>
          </div>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_image-image"></div>
          <img src={IMG4} alt="" />
          <h3>Grey To The Green</h3>
          <div className="portfolio_item-cta">
            {" "}
            <a
              href="https://greytothegreen.com/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              View
            </a>
          </div>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_image-image"></div>
          <img src={IMG5} alt="" />
          <h3>Parinitha Technologies</h3>
          <div className="portfolio_item-cta">
            {" "}
            <a
              href="https://parinithatechnologies.in/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              View
            </a>
          </div>
        </article>

        <article className="portfolio_item">
          <div className="portfolio_image-image"></div>
          <img src={IMG7} alt="" />
          <h3>Jay Varma -The Artist</h3>
          <div className="portfolio_item-cta">
            {" "}
            <a
              href="https://jayvarma.com/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              View
            </a>
          </div>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_image-image"></div>
          <img src={IMG8} alt="" />
          <h3>NK Fearless</h3>
          <div className="portfolio_item-cta">
            {" "}
            <a
              href="https://nkfearless.com/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              View
            </a>
          </div>
        </article>
      </div>
    </section>
  );
};

export default portfolio;
